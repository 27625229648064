import { WorkHistoryIcon, ArrowRight01Icon, DashboardSpeed01Icon, Calendar03Icon, Settings02Icon, UserMultiple02Icon, Logout02Icon, Analytics02Icon, GoogleDocIcon, Book01Icon, MenuRestaurantIcon, DistributeVerticalBottomIcon, PlusSignIcon, Cancel01Icon } from 'hugeicons-react'
import React, { useEffect, useState } from 'react'
import '../../styles/sideBar/sideBar.css'
import { Link, useNavigate, } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import AuthService from '../../services/auth'

const Branding = () => {
    return (
        <div className='branding'>
            <h1 className='site-title'>CSR <span>Back</span> Office</h1>
        </div>
    )
}
const Overview = ({ handleActiveLink }) => {
    const activeLink = localStorage.getItem('activeLink') || ''

    return (
        <div className='overview'>
            <Link onClick={() => handleActiveLink('/v2/')} to={'/v2/'} className={`menu-link ${activeLink === '/v2/' ? 'active' : ''} `}>
                <DashboardSpeed01Icon
                    size={20}
                    variant={"stroke"}
                />
                <h4 className='text'>Overview</h4>
            </Link>
            <Link onClick={() => handleActiveLink('/v2/employees/')} to={'/v2/employees/'} className={`menu-link ${activeLink === '/v2/employees/' ? 'active' : ''}`}>
                <UserMultiple02Icon
                    size={20}
                    variant={"stroke"}
                />

                <h4 className='text'>Employees</h4>
            </Link>
            <Link onClick={() => handleActiveLink('/v2/leaves/')} to={'/v2/leaves/'} className={`menu-link ${activeLink === '/v2/leaves/' ? 'active' : ''}`}>
                <Calendar03Icon
                    size={20}
                    variant={"stroke"}
                />
                <h4 className='text'>Leaves</h4>
            </Link>

            <Link className='menu-link'>
                <Settings02Icon
                    size={20}
                    variant={"stroke"}
                />
                <h4 className='text'>Settings</h4>
            </Link>
        </div>
    )
}

const Projects = ({ activeSubMenu, toggleSubMenu }) => {
    const [activeLink, setActiveLink] = useState('')
    useState(() => (
        setActiveLink(localStorage.getItem('activeLink') || '')
    ))
    return (
        <div className='module projects'>
            <div onClick={() => toggleSubMenu('projects', '/v2/projects/')} className={`title ${activeSubMenu === 'projects' ? 'submenu-open' : ''} ${activeLink === '/v2/projects/' ? 'active' : ''}`}>
                <h4 className='text'>Projects</h4>
                <ArrowRight01Icon className='icon'
                    size={20}
                    variant={'stroke'}
                />
            </div>

            <div className={`links ${activeSubMenu === 'projects' ? 'open' : 'close'}`}>
                <Link to={'/v2/projects/'} className={`menu-link `}>
                    <Book01Icon className='icon' size={16} />
                    <h4 className='text'>Overview</h4>
                </Link>
                <Link className='menu-link'>
                    <WorkHistoryIcon size={16} />
                    <h4 className='text'>Tasks</h4>
                </Link>
                <Link className='menu-link'>
                    <GoogleDocIcon size={16} />
                    <h4 className='text'>Projects</h4>
                </Link>
                <Link className='menu-link'>
                    <Book01Icon size={16} />
                    <h4 className='text'>Reports</h4>
                </Link>
            </div>
        </div>
    )
}

const TrainingCenter = ({ activeSubMenu, toggleSubMenu, isLinkActive }) => {
    const [activeLink, setActiveLink] = useState('')
    useState(() => (
        setActiveLink(localStorage.getItem('activeLink') || '')
    ))
    return (
        <div className='module training-center'>
            <div onClick={() => toggleSubMenu('trainingCenter', '/v2/training-center/')} className={`title ${activeSubMenu === 'trainingCenter' ? 'submenu-open' : ''} ${activeLink === '/v2/training-center/' ? 'active' : ''}`}>
                <h4 className='text'>Training Center</h4>
                <ArrowRight01Icon className='icon'
                    size={20}
                    variant={'stroke'}
                />
            </div>

            <div className={`links ${activeSubMenu === 'trainingCenter' ? 'open' : 'close'}`}>
                <Link to={'/v2/training-center/'} className={`menu-link ${isLinkActive('new-side-bar') ? 'active' : ''}`}>
                    <Analytics02Icon className='icon' size={16} />
                    <h4 className='text'>Overview</h4>
                </Link>
                <Link to={'/v2/training-center/cohorts/'} className='menu-link'>
                    <GoogleDocIcon size={16} />
                    <h4 className='text'>Cohorts</h4>
                </Link>
                <Link className='menu-link'>
                    <Book01Icon size={16} />
                    <h4 className='text'>Trainings</h4>
                </Link>

                <Link className='menu-link'>
                    <UserMultiple02Icon size={16} />
                    <h4 className='text'>Members</h4>
                </Link>
            </div>
        </div>
    )
}

const JobMatch = ({ activeSubMenu, toggleSubMenu }) => {
    const [activeLink, setActiveLink] = useState('')
    useState(() => (
        setActiveLink(localStorage.getItem('activeLink') || '')
    ))
    return (
        <div className='module job-match'>
            <div onClick={() => toggleSubMenu('jobMatch', '/v2/job-match/')} className={`title ${activeSubMenu === 'jobMatch' ? 'submenu-open' : ''} ${activeLink === '/v2/job-match/' ? 'active' : ''}`}>
                <h4 className='text'>Job Match</h4>
                <ArrowRight01Icon className='icon'
                    size={20}
                    variant={'stroke'}
                />
            </div>
            <div className={`links ${activeSubMenu === 'jobMatch' ? 'open' : 'close'}`}>
                <Link to={'/v2/job-match/'} className='menu-link'>
                    <Analytics02Icon size={16} />
                    <h4 className='text'>Overview</h4>
                </Link>
                <Link to={'/v2/employers/'} className='menu-link'>
                    <UserMultiple02Icon size={16} />

                    <h4 className='text'>Employers</h4>
                </Link>
                <Link className='menu-link'>
                    <UserMultiple02Icon size={16} />
                    <h4 className='text'>Candidates</h4>
                </Link>
                <Link className='menu-link'>
                    <GoogleDocIcon size={16} />
                    <h4 className='text'>Interviews</h4>
                </Link>
                <Link to={'/v2/job-match/jobs/'} className='menu-link'>
                    <Book01Icon size={16} />
                    <h4 className='text'>Jobs</h4>
                </Link>
            </div>
        </div>
    )
}

const Canteen = ({ activeSubMenu, toggleSubMenu }) => {
    const [activeLink, setActiveLink] = useState('')
    useState(() => (
        setActiveLink(localStorage.getItem('activeLink') || '')
    ))
    return (
        <div className='module canteen'>
            <div onClick={() => toggleSubMenu('canteen', '/v2/canteen/')} className={`title ${activeSubMenu === 'canteen' ? 'submenu-open' : ''} ${activeLink === '/v2/canteen/' ? 'active' : ''}`}>
                <h4 className='text'>Canteen</h4>
                <ArrowRight01Icon className='icon'
                    size={20}
                    variant={'stroke'}
                />
            </div>
            <div className={`links ${activeSubMenu === 'canteen' ? 'open' : 'close'}`}>
                <Link to='/v2/canteen/admin' className='menu-link'>
                    <Analytics02Icon size={16} />
                    <h4 className='text'>Overview</h4>
                </Link>
                <Link to='/v2/canteen/order' className='menu-link'>
                    <PlusSignIcon size={16} />
                    <h4 className='text'>Order something</h4>
                </Link>
                <Link className='menu-link'>
                    <MenuRestaurantIcon size={16} />
                    <h4 className='text'>My Orders</h4>
                </Link>
                <Link to='/v2/canteen/' className='menu-link'>
                    <DistributeVerticalBottomIcon size={16} />
                    <h4 className='text'>Menu items</h4>
                </Link>
            </div>
        </div>
    )
}

const Blog = ({ activeSubMenu, toggleSubMenu }) => {
    const [activeLink, setActiveLink] = useState('')
    const permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : []
    const canModifyBlog = permissions && permissions.includes('Director')
    useState(() => (
        setActiveLink(localStorage.getItem('activeLink') || '')
    ))

    return (
        <div className='module blog'>
            <div onClick={() => toggleSubMenu('blog', '/v2/blog/')} className={`title ${activeSubMenu === 'blog' ? 'submenu-open' : ''} ${activeLink === '/v2/blog/' ? 'active' : ''}`}>
                <h4 className='text'>CSR Blog</h4>
                <ArrowRight01Icon className='icon'
                    size={20}
                    variant={'stroke'}
                />
            </div>
            <div className={`links ${activeSubMenu === 'blog' ? 'open' : 'close'}`}>
                {/* <Link to='/v2/blog/analytics/' className='menu-link'>
                    <Analytics02Icon size={16} />
                    <h4 className='text'>Analytics</h4>
                </Link> */}
                {
                    canModifyBlog ?

                        <Link to='/v2/blog/new/' className='menu-link'>
                            <PlusSignIcon size={16} />
                            <h4 className='text'>New Blog</h4>
                        </Link>
                        : ''
                }
                <Link to={'/v2/blog/recent-blogs/'} className='menu-link'>
                    <MenuRestaurantIcon size={16} />
                    <h4 className='text'>Recent Blogs</h4>
                </Link>
                <Link to='/v2/blog/categories/' className='menu-link'>
                    <DistributeVerticalBottomIcon size={16} />
                    <h4 className='text'>Categories</h4>
                </Link>
            </div>
        </div>
    )
}
const Settings = () => {
    const navigate = useNavigate()
    const handleLogout = () => {
        try {
            AuthService.logout()
            navigate('/login')
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <div className='settings'>
            <div onClick={handleLogout} className="title logout">
                <Logout02Icon
                    size={20}
                    variant={'stroke'}
                />
                <h4 className='text'>Logout</h4>
            </div>
        </div>
    )
}
const NewSideBar = ({ sideBarOpen, toggleSideBarOpen }) => {
    const [activeSubMenu, setActiveSubMenu] = useState('')
    const handleActiveLink = (path) => {
        localStorage.setItem('activeLink', path)
    }
    const toggleSubMenu = (subMenu, path) => {
        setActiveSubMenu(activeSubMenu !== subMenu ? subMenu : '')
        handleActiveLink(path)
    };

    const location = useLocation();
    const isLinkActive = (path) => {
        return location.pathname.includes(path) && path !== '/';
    };

    return (
        <div className={`dashboard-side-bar ${sideBarOpen ? 'side-bar-open' : ''}`}>
            <div className="top">
                <Branding />
                <div onClick={toggleSideBarOpen} className="close-icon">
                    <Cancel01Icon size={42} color='white' />
                </div>
                <div className="modules">
                    {/* <Overview handleActiveLink={handleActiveLink} />
                    <div className="divider"></div> */}
                    {/* <Projects handleActiveLink={handleActiveLink} activeSubMenu={activeSubMenu} toggleSubMenu={toggleSubMenu} />
                    <div className="divider"></div> */}
                    <TrainingCenter activeSubMenu={activeSubMenu} toggleSubMenu={toggleSubMenu} isLinkActive={isLinkActive} />
                    <div className="divider"></div>
                    {/* <JobMatch handleActiveLink={handleActiveLink} activeSubMenu={activeSubMenu} toggleSubMenu={toggleSubMenu} />
                    <div className="divider"></div> */}
                    {/* <Canteen handleActiveLink={handleActiveLink} activeSubMenu={activeSubMenu} toggleSubMenu={toggleSubMenu} />
                    <div className="divider"></div> */}
                    <Blog handleActiveLink={handleActiveLink} activeSubMenu={activeSubMenu} toggleSubMenu={toggleSubMenu} />
                </div>
            </div>
            <div className="bottom">
                <Settings />
            </div>
        </div>
    )
}

export default NewSideBar

