import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import FormattedDate from '../../../components/formattedDate'
import CustomSelectInput from '../../../components/customSelectInput'
import ExportExcel from '../../../utils/exportExcel'
import api, { API_URL } from '../../../utils/api'
import DashboardContainerV2 from '../../../components/dashbaord/dashboardContainerV2.jx'
import "../../../styles/components/trainingcohortoverview.css"
import CohortSearch from '../../../components/trainingCenter/details/cohortSearch'
import {
    ArrowDown01Icon, MoreVerticalIcon, DepartementIcon, Layers01Icon,
    DocumentValidationIcon, Home01Icon, Notification02Icon, CancelCircleIcon
} from 'hugeicons-react'
import "../../../styles/trainingCenter/cohorts/cohortapplicant.css"
import ApplicationTab from './applicationTab'
import trainingCenter, { trainingCenterAPI } from '../../../utils/trainingCenter'

const handleSearch = (items, searchString, selectedDepartment) => {
    return items.filter((item) => {
        const fullName = `${item.first_name ?? ''} ${item.last_name ?? ''}`.trim().toLowerCase();

        // const fullName = `${item.user.first_name} ${item.user.last_name}`.toLowerCase();
        const departmentName = item.department?.toLowerCase() || '';
        return (
            (searchString.length < 3 ||
                fullName.includes(searchString.toLowerCase()) ||
                item.email.toLowerCase().includes(searchString.toLowerCase()) ||
                item.phone_number.toLowerCase().includes(searchString.toLowerCase())) &&
            (selectedDepartment === '' || departmentName.includes(selectedDepartment.toLowerCase()))
        );
    });
};
const handleSort = (items, sortOption) => {
    const sortedItems = [...items];
    if (sortOption === 'Name A-Z') {
        sortedItems.sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
            const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });
    } else if (sortOption === 'Date Applied') {
        sortedItems.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }
    return sortedItems;
};


const getApplications = async (cohortId) => {
    console.log("Getting applications");
    try {
        const { data, status } = await trainingCenter.get(
            `${trainingCenterAPI}/trainings/cohorts/${cohortId}/applications/`
        );
        localStorage.setItem(`cohort${cohortId}Applications`, JSON.stringify(data));

        return {
            success: status === 200,
            data: status === 200 ? data : null,
            message: status === 200 ? null : "Failed to fetch applications"
        };
    } catch (error) {
        console.error("Application fetch error:", error?.response?.data || error.message);
        return {
            success: false,
            data: null,
            message: error?.response?.data?.message || "Failed to fetch applications"
        };
    }
};

const TrainingCenterApplications = ({ cohortDetails }) => {
    const { cohortId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [applications, setApplications] = useState([])
    const [searchString, setSearchString] = useState('')
    const [filteredApplications, setFilteredApplications] = useState([])
    const [sortOptions, setSortOptions] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState('')
    const [selectedApplicant, setSelectedApplicant] = useState(null)
    useEffect(() => {
        const storedApplications = localStorage.getItem(`cohort${cohortId}Applications`)
        if (storedApplications) {
            const storedData = JSON.parse(storedApplications);
            setApplications(storedData);
            setFilteredApplications(storedData);
        }
        const fetchApplications = async () => {
            try {
                console.log('Authenticating with CBO')
                const response = await api(`${API_URL}/api/training-center/cohort/${cohortId}/applications/`);
                if (response.status === 200) {
                    sessionStorage.setItem("trainingCenterAccessToken", response.data)

                    // get applocation
                    const application_response = await getApplications(cohortId)
                    if (application_response.success) {
                        console.log("Applications fetched successfully", application_response.data)
                        setApplications(application_response.data)
                        setFilteredApplications(application_response.data);
                        setIsLoading(false)
                    }
                }

            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };
        fetchApplications();
    }, [cohortId]);


    useEffect(() => {
        const results = handleSearch(applications, searchString, selectedDepartment);
        const sortedResults = handleSort(results, sortOptions);
        setFilteredApplications(sortedResults);
    }, [searchString, selectedDepartment, sortOptions, applications]);
    const handleSearchInput = (e) => {
        setSearchString(e.target.value);
    };

    const handleActionIconClick = (applicant) => {
        setSelectedApplicant(applicant);
    };



    return isLoading && applications && applications.length > 0
        ? <p>Getting applications...</p> : isLoading && (!applications || !applications.length > 0) ? '..Loading' : (
            <div className="applications-container contents-container">

                <CohortSearch
                    searchString={searchString}
                    setSearchString={setSearchString}
                    selectedDepartment={selectedDepartment}
                    setSelectedDepartment={setSelectedDepartment}
                    sortOptions={sortOptions}
                    setSortOptions={setSortOptions}
                    filteredApplications={filteredApplications}
                />

                <div className='flex-child'>
                    <div className="recent-transactions">
                        <div className="table-contents">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Full name</th>
                                        <th>Gender</th>
                                        <th>Emails</th>
                                        <th>Phone number</th>
                                        <th>Department</th>
                                        <th>Experience</th>
                                        <th>Date applied</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredApplications.length > 0 ? (
                                        filteredApplications.map((application, index) => (
                                            <tr key={index}>
                                                <td>{application.first_name || ''} {application.last_name || '-'} </td>
                                                <td>{application.gender || '-'}</td>
                                                <td>{application.email || '-'}</td>
                                                <td>{application.phone_number || '-'}</td>
                                                <td>{application.department || '-'}</td>
                                                <td>{application.training_level || ''}</td>
                                                <td> <FormattedDate date={application.date_applier || ''} /> </td>
                                                <td> <MoreVerticalIcon
                                                    size={24}
                                                    color={"#000000"}
                                                    variant={"stroke"}
                                                    onClick={() => handleActionIconClick(application)}
                                                /></td>
                                            </tr>
                                        ))
                                    ) : (<tr> <td colSpan="7">No applications</td> </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {selectedApplicant && (
                    <>
                        <div className="cohort-popup popup">
                            <CancelCircleIcon
                                size={24}
                                color={"#000000"}
                                variant={"stroke"}
                                className='close-icon'
                                onClick={() => setSelectedApplicant(null)}
                            />
                            <div className='applicant-info'>
                                <div className="applicant-header">
                                    <div className="name-date">
                                        <h3>{selectedApplicant.first_name} {selectedApplicant.last_name}</h3>
                                        <p><FormattedDate date={selectedApplicant.date_applier || ''} /> </p>
                                    </div>
                                    <div className="status-action">
                                        <div className="status">
                                            <p>Status</p>
                                            <h4>Onboarding</h4>
                                            <ArrowDown01Icon
                                                size={20}
                                                color={"#145C9E"}
                                                variant={"stroke"}
                                            />
                                        </div>
                                        <div className='action'>
                                            <MoreVerticalIcon
                                                size={24}
                                                color={"#000000"}
                                                variant={"stroke"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="cohort-background">
                                    <div className="cohort-text">
                                        <div className="text-icon">
                                            <Home01Icon
                                                size={20}
                                                color={"#727272"}
                                                variant={"stroke"}
                                            />
                                            <p>Cohort</p>
                                        </div>

                                        <h2>{cohortDetails.title}</h2>
                                    </div>

                                    <div className="cohort-text">
                                        <div className="text-icon">
                                            <DepartementIcon
                                                size={20}
                                                color={"#727272"}
                                                variant={"stroke"}
                                            />
                                            <p>Department</p>
                                        </div>

                                        <h2>{selectedApplicant.department} </h2>
                                    </div>

                                    <div className="cohort-text">
                                        <div className="text-icon">
                                            <Layers01Icon
                                                size={20}
                                                color={"#727272"}
                                                variant={"stroke"}
                                            />
                                            <p>Level</p>
                                        </div>

                                        <h2>{selectedApplicant.experience}</h2>
                                    </div>

                                    <div className="cohort-text">
                                        <div className="text-icon">
                                            <DocumentValidationIcon
                                                size={20}
                                                color={"#727272"}
                                                variant={"stroke"}
                                            />
                                            <p>Background</p>
                                        </div>

                                        <h2>{selectedApplicant.background || "Not Provided"}</h2>
                                    </div>
                                </div>

                                <div className="training-reminder">
                                    <div className="training-payed">
                                        <p>Training fees</p>
                                        <p>#23455</p>
                                        <h4>Not payed</h4>
                                    </div>

                                    <div className='icon-reminder'>
                                        <Notification02Icon
                                            size={20}
                                            variant={"stroke"}
                                        />
                                        <p>Send reminder</p>
                                    </div>
                                </div>
                            </div>
                            <ApplicationTab
                                selectedApplicant={selectedApplicant}
                            />
                        </div>
                        <div className="cohort-overlay"></div>

                    </>
                )}



            </div>
        )
};


export default TrainingCenterApplications;